import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import ls from 'local-storage'
import ga from 'utils/ga';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i); // eslint-disable-line


class Subscribe extends Component {
  constructor(props) {
    super(props);
    let selectedPackage = 2
    let welcomeText = false
    let redirectToTender = ''
    let primaryCategory = '45' 


    // check if ?pako=x is present
    if(this.props.locationData.search){
      const queryString = require('query-string');
      const parsedParams = queryString.parse(this.props.locationData.search);
      if(parsedParams.pako){
          selectedPackage = parsedParams.pako;
      }
      if(parsedParams.welcomeText){
        welcomeText = true;
      }
      
      if(parsedParams.tid){
        redirectToTender = parsedParams.tid;
        //alert('this user was trying to watch a tender')
      }

    }

    if(ls.get('lastCategory')){
      primaryCategory = ls.get('lastCategory');
      //alert('this user was trying to watch a tender')
    }



    this.state = {
        // form fields
        email: '',
        email_password:'',
        company_name:'',
        kontakt_personi: '',
        numri_fiskal:'',
        adresa: '',
        package: selectedPackage,
        category1:primaryCategory,
        category2:'',
        category3:'',
        //end of form fields
        errors: {
          email:'',
          email_password:'',
          company_name:''
        },
        showModal: false,
        showRedirectModal: false,
        categories: [],
        subscriptionStatus:'',
        showWelcomeText: welcomeText,
        welcomeText1: 'Urime! Jeni vetëm një hap larg për ti shikuar tenderat.',
        welcomeText2: 'Regjistrohuni falas për 7 ditë, nëpërmjet fushave më poshtë.',
        redirectToTender: redirectToTender,
        seconds: 3,
        jwtToken: ''
      };


    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.validateField = this.validateField.bind(this);
    this.startRedirectInterval = this.startRedirectInterval.bind(this);
  }

  logInUser() {
    this.props.logInUser();
  }

  handleClose(){
    this.setState({showModal:false})
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  startRedirectInterval(urlToFollow){
    this.setState({ showRedirectModal:true });
		this.myInterval = setInterval(() => {
			if(this.state.seconds === 0) {
        window.clearInterval(this.myInterval);
        localStorage.setItem('jwtToken', this.state.jwtToken);
        this.logInUser()
        //this.props.history.push('/tenders/'+this.state.redirectToTender+'?jwt='+this.state.jwtToken+'&statusi=regjistrimiFunnel');
        this.props.history.push(urlToFollow);
			} else {
				this.setState(({ seconds }) => ({
					seconds: seconds - 1
				  }))			
			}
	
		  }, 1000)
		  	  
	}

  handleSubmit(event) {
    //alert('A name was submitted: ' + this.state.value);
    axios.post('subscribe',
    {
        email: this.state.email,
        email_password: this.state.email_password,
        company_name: this.state.company_name,
        adresa: this.state.adresa,
        kontakt_personi: this.state.kontakt_personi,
        numri_fiskal: this.state.numri_fiskal,
        pako: this.state.package,
        category1: this.state.category1,
        category2: this.state.category2,
        category3: this.state.category3,
        source: ls.get('source')
      }).then(res => {
        if(this.state.redirectToTender.length < 1) {
          this.setState({subscriptionStatus: res.data.message, jwtToken: res.data.jwt});
          localStorage.setItem('jwtToken', this.state.jwtToken);
          this.logInUser()
          //this.props.history.push('/tenders/:id')
          this.startRedirectInterval('/tenders/:id')
        } else {
          this.setState({jwtToken: res.data.jwt});
          this.startRedirectInterval('/tenders/'+this.state.redirectToTender+'?jwt='+this.state.jwtToken+'&statusi=regjistrimiFunnel')
        }
        
      }).catch(error => {
        this.setState({errors:error.response.data.errors, showModal: true})
        //alert(JSON.stringify(error))
      })
    //event.preventDefault();
  }

  getErrorLabels = () => {
    const labels = {'company_name':'Emri i kompanisë duhet ti ketë së paku 3 shkronja','email':'Emaili nuk është valid!','email_password':'Passwordi duhet ti ketë së paku 8 karaktere','new_password':'Passwordi i ri duhet ti ketë se paku 8 karaktere','confirm_password':'Passwordi i konfirmuar nuk është i njejtë me passwordin e ri'}
    return labels
  }

  validateField = (event) => {
      event.preventDefault();
      const { name, value } = event.target;
      let errors = this.state.errors;
      let errorLabels = this.getErrorLabels();


      if(name === 'email') {
        errors.email = validEmailRegex.test(value) ? '': errorLabels.email
      }
      else if(name === 'email_password') {
        errors.email_password = value.length >= 8 ? '': errorLabels.email_password;
      } else if(name === 'new_password'){
        errors.password = this.state.formData.password.length >= 8 ? '': errorLabels.password;
        errors.new_password = value.length >= 8 ? '': errorLabels.new_password;
      } else if(name === 'confirm_password'){
        errors.confirm_password = this.state.new_password === this.state.confirm_password ? '': errorLabels.confirm_password;
      } else if(name === 'company_name'){
        errors.company_name = value.length >= 3 ? '': errorLabels.company_name;
      }
      //alert(JSON.stringify(errors));
      this.setState({errors, [name]: value});
  }

  componentDidMount() {
    axios.get('categories_list')
      .then(res => {
        const categories = res.data;
        this.setState({ categories: categories });
      })

      ga.set({ page: "Subscribe" }); // Update the user's current page
      ga.pageview(window.location.pathname); // Record a pageview for the given page  
      //this.startRedirectInterval()
  }


  render() {
    const {errors} = this.state;
    axios.get('subscribe') // call endpoint for analytics purposes only

    return (

      <div className="row" style={{marginLeft:'20px'}}>

            <div className="col-md-6">
              <div className="form-group">
              
              {this.state.showWelcomeText && 
              <p className='welcome_text' style={{fontSize:'17px', color:'white', backgroundColor:'black', padding:'5px'}}>
                {this.state.welcomeText1}<br />
                {this.state.welcomeText2}                                
                </p>
              }  
              
              
              <label className="field-description">Emaili:*</label>
              {errors.email.length > 0 &&
                  <span className='error'><br />{errors.email}</span>}
                <input type="email" name="email" className="form-control input-sm" onBlur={this.validateField} onChange={this.handleChange} required/>
              </div>


              <div className="form-group">
              <label className="field-description">Passwordi:*</label>
              {errors.email_password.length > 0 &&
                  <span className='error'><br />{errors.email_password}</span>
              }
                <input type="password" name="email_password" className="form-control input-sm" onBlur={this.validateField} onChange={this.handleChange} required/>
              </div>


              <label style={{borderBottom:'3px solid yellow'}}>Kategoria kryesore e tenderave:</label>
                <br/><p>(Ju do të pranoni të gjithë tenderat e publikuar në të gjitha kategoritë,
                  por primare do të jetë kjo kategori)</p>


              <select className="form-control" name="category1" value={this.state.category1} onChange={this.handleChange}>
                    {this.state.categories.map(category => (
                        <option key={category.cat_id} value={category.cat_id}>{category.cat_name}</option>
                    ))}
              </select>

              {ls.get('source') === 'website' &&       
               <>
                <br/><label style={{marginTop:'10px'}}>Kategoria e dytë(opcionale):
                  </label>

                  <select className="form-control" name="category2" value={this.state.category2} onChange={this.handleChange}>
                          <option>---</option>
                          {this.state.categories.map(category => (
                            <option key={category.cat_id} value={category.cat_id}>{category.cat_name}</option>
                        ))}
                  </select>

                  <br/><label style={{marginTop:'10px'}}>Kategoria e tretë(opcionale):
                  </label>

                  <select className="form-control" name="category3" value={this.state.category3} onChange={this.handleChange}>
                          <option>---</option>
                          {this.state.categories.map(category => (
                            <option key={category.cat_id} value={category.cat_id}>{category.cat_name}</option>
                        ))}
                  </select>
                </>
                }

              <div className="form-group">
                <br /><label style={{borderBottom:'3px solid yellow'}}>Të dhënat e kompanisë/individit:</label>
                {errors.company_name.length > 0 &&
                    <span className='error'><br />{errors.company_name}</span>
                }
                <input type="text" name="company_name" className="form-control input-sm" placeholder="Emri i kompanisë/Individit*" onBlur={this.validateField} onChange={this.handleChange} required/>
              </div>

              {ls.get('source') === 'website' && 
               <> 
                <div className="form-group">
                      <input type="text" name="numri_fiskal" className="form-control input-sm" placeholder="Numri Fiskal/NIPT" onChange={this.handleChange} />
                    </div>

                    <div className="form-group">
                      <input type="text" name="adresa" className="form-control input-sm" placeholder="Adresa e kompanisë" onChange={this.handleChange} />
                    </div>

                    <div className="form-group">
                      <input type="text" name="kontakt_personi" className="form-control input-sm" placeholder="Kontakt personi" onChange={this.handleChange} />
                    </div>
                    </>      
                }  

                <div className="form-group">

                  <label style={{borderBottom:'3px solid yellow'}}>Pakoja e abonimit: <small>(<a href="/pricing" target="_blank">klikoni këtu</a> për përshkrimin e secilës pako)</small></label>
                  <br/><select className="form-control" name="package"  value={this.state.package} onChange={this.handleChange}>
                    <option value="0">7 ditë falas - periudhë provuese</option>
                    <option value="1">Pako Për Individ - 212.4€ në vit, apo 15 EUR/muaj pa TVSH</option>
                    <option value="2">Pako Për Kompani të Vogla - 325.68€ në vit, apo 23 EUR/muaj pa TVSH</option>
                    <option value="3">Pako për Kompani të Mesme dhe të Mëdha - 424.8€ në vit, apo 30 EUR/muaj pa TVSH</option>
                  </select>

                  {this.state.subscriptionStatus.length > 0 &&
                  <span className='success_message'><br />{this.state.subscriptionStatus}<br /></span>
                  }

                  <br/><input type="submit" value="Regjistrohu" onClick={ () => this.handleSubmit() } className="btn btn-info btn-block" />


                </div>
    

            </div>


            <div className="col-md-6">
              <div className="modal_content">
                      <Modal
                        open={this.state.showRedirectModal}
                        showCloseIcon={false}
                        focusTrapped={false}
                        center
                        closeOnOverlayClick={false}
                        closeOnEsc={false}               
                        styles={{ overlay: {  padding:'40px' } }}>

                        <div style={{textAlign:'center'}}><h1>{this.state.seconds}
                        </h1></div>
                        <h5 style={{fontSize:"15px",borderBottom:'0px'}}>
                          Regjistrimi u krye me sukses, tani po ri-drejtoheni te faqja e tenderit.</h5>

                      </Modal>
                      </div>
            </div>

                            <Modal show={this.state.showRedirectModal}>
                            <div style={{textAlign:'center'}}>
                              <h5 style={{borderBottom:'0px', padding:'20px', paddingBottom: '0px', fontSize:'30px'}}>
                              {this.state.seconds}
                              </h5>

                            </div>


                              <Modal.Body>
                                  <h5 style={{fontSize:"15px",borderBottom:'0px'}}>
                                  Regjistrimi u krye me sukses, tani po ri-drejtoheni te faqja e tenderit.</h5>
                              </Modal.Body>

                            </Modal>

            <Modal show={this.state.showModal} onHide={this.handleClose}>

              <Modal.Header closeButton>
                <Modal.Title>Probleme me regjistrim:</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <ul>
                    {Object.keys(errors).map(function(keyName, keyIndex) {
                        if(errors[keyName].length > 0){
                          return <li key={keyIndex}>{errors[keyName]}</li>
                        } else {
                          return false
                        }
                    })}
                </ul>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Mbylle
                </Button>
              </Modal.Footer>

            </Modal>

         

      </div>

    );
  }
}

export default withRouter(Subscribe);
