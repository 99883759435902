import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import ga from 'utils/ga';

class Profile extends Component {
  constructor(props) {
    super(props);

    // form fields
    var formData = {
      email: '',
      password:'',
      new_password:'',
      confirm_password:'',
      company_name:'',
      contact_person: '',
      fiscalnr_nipt:'',
      business_address: '',
      pako: 2,
      category1:'01',
      category2:'',
      category3:'',
      status:''
    }


    let confirmSubscription = false
    let showCustomConfirmSubscription = false
    let subscriptionConfirmedPako = 2
    let showBottomPackages = true

    // check if params confirm_subscription or pako are present
    if(this.props.locationData.search){
      const queryString = require('query-string');
      const parsedParams = queryString.parse(this.props.locationData.search);
      if(parsedParams.confirm_subscription){
          confirmSubscription = parsedParams.confirm_subscription;
          showBottomPackages = false;
      }
      if(parsedParams.pako){
          subscriptionConfirmedPako = parsedParams.pako;
          showCustomConfirmSubscription = true;
      }
    }

    this.state = {
        formData: formData,
        subscriptionConfirmedPako: subscriptionConfirmedPako,
        showCustomConfirmSubscription: showCustomConfirmSubscription,
        changePassword: false,
        errors: {
          password:'',
          new_password:'',
          confirm_password:'',
          company_name:''
        },
        categories: [],
        successMessage:'',
        showModal: false,
        confirmSubscription: confirmSubscription,
        confirmButtonDisabled: false,
        confirmSubscriptionText: '',
        confirmSubscriptionTextColor: 'black',
        isLoading: false,
        showBottomPackages: showBottomPackages
    };


    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.validateField = this.validateField.bind(this);
    this.toggleChangePassword = this.toggleChangePassword.bind(this);
    this.handleConfirmSubscription = this.handleConfirmSubscription.bind(this);
    this.handleConfirmSubscriptionChange = this.handleConfirmSubscriptionChange.bind(this);
  }

  getErrorLabels = () => {
    const labels = {
      'company_name':'Company name has to be at least 3 characters',
      'email':'Emaili nuk është valid!',
      'password':'Passwordi duhet të jetë së paku 8 shkronja ose numra',
      'new_password':'Passwordi i ri duhet t\'i ketë së paku 8 shkronja ose numra',
      'confirm_password':'Passwordi i konfirmuar nuk është i njejtë me passwordin e ri'}
    return labels
  }

  handleConfirmSubscription() {
    this.setState({ isLoading: true })
    axios.post('confirm_subscription',
      {
          pako: this.state.subscriptionConfirmedPako,
       }
     )
      .then(res => {
        const response = res.data;
        this.setState({ isLoading: false, confirmSubscriptionText: response.message, confirmSubscriptionTextColor: response.color, confirmButtonDisabled: true });
      }).catch(error => {
          alert(JSON.stringify(error))
          //this.setState({ isLoading: false, confirmSubscriptionText: error.response.data.message, confirmSubscriptionTextColor: error.response.data.color, confirmButtonDisabled: true });
      })
  }

  handleConfirmSubscriptionChange(event) {
    const target = event.target;
    const value = target.value;
    this.setState({showCustomConfirmSubscription: true, subscriptionConfirmedPako: value})
  }

  handleClose(){
    this.setState({showModal:false})
  }

  toggleChangePassword(event) {
    this.setState({changePassword: !this.state.changePassword})
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    //let errors = this.state.errors;
    let formData = this.state.formData;
    //let errorLabels = this.getErrorLabels();

    formData[name] = value;
    this.setState({formData, [name]: value});

  }



  handleSubmit(event) {
    // Perform validation on frontend first and then backend
    let errors = this.state.errors;
    let errorLabels = this.getErrorLabels()
    let formData = this.state;
    var errorsFound = false;

    // Frontend form validation
    // 1. Check for active errors first
    Object.keys(errors).map(function(keyName, keyIndex) {
      if(errors[keyName].length > 0) {
        return errorsFound = false;
      } else {
        return null;
      }
    })

    // 2. Check formData
    if(this.state.formData.company_name.length < 3){
      errors.company_name = errorLabels.company_name;
      errorsFound = true;
    }

    // 3. Change Password section
    if(this.state.formData.new_password.length > 0 || this.state.formData.confirm_password.length > 0){
        if(this.state.formData.password.length < 8) {
          errors.password = errorLabels.password;
          errorsFound = true;
        }
        if(this.state.formData.new_password.length < 8) {
          errors.new_password = errorLabels.new_password;
          errorsFound = true;
        }

        if(this.state.formData.new_password.length !== this.state.formData.confirm_password.length) {
          errors.confirm_password = errorLabels.confirm_password;
          errorsFound = true;
        }
    }


    // no errors found, proceed to server
    if(!errorsFound){
        axios.post('profile', {data: formData})
        .then(res => {
            // Backend form validation
            if(res.data.status === 'error') {
              Object.keys(res.data.errors).map(function(keyName, keyIndex) {
                return errors[keyName] = res.data.errors[keyName]
              })
              this.setState({errors, showModal: true });
            } else {
              this.setState({successMessage:'Të dhënat u modifikuan suksesshëm!'})
            }
        }).catch(error => {
            this.setState({errors:error.response.data.errors, showModal: true})
        })
    } else { // errors found, display them in Modal
    this.setState({errors,showModal:true});
    event.preventDefault();
    }



  }

  // frontend field validation for Change Password section
  validateField = (event) => {
      event.preventDefault();
      const { name, value } = event.target;
      let errors = this.state.errors;
      let errorLabels = this.getErrorLabels();


      if(name === 'password') {
        errors.password = value.length >= 8 ? '': errorLabels.password;
      } else if(name === 'new_password'){
        errors.password = this.state.formData.password.length >= 8 ? '': errorLabels.password;
        errors.new_password = value.length >= 8 ? '': errorLabels.new_password;
      } else if(name === 'confirm_password'){
        errors.confirm_password = this.state.new_password === this.state.confirm_password ? '': errorLabels.confirm_password;
      }
      this.setState({errors, [name]: value});
  }

  componentDidMount() {
    // get user profile
    axios.get('profile')
      .then(res => {
        const response = res.data;
        this.setState({ formData: response });
      })

    // get category list
    axios.get('categories_list')
      .then(res => {
        const categories = res.data;
        this.setState({ categories: categories });
      })

      ga.set({ page: "Profile" }); // Update the user's current page
      ga.pageview(window.location.pathname); // Record a pageview for the given page
  }


  render() {
    const {errors} = this.state;
    const confirmedPakoFinalValue = this.state.showCustomConfirmSubscription ? this.state.subscriptionConfirmedPako : this.state.formData.pako

    return (

      <div className="row" style={{marginLeft:'20px'}}>

            <div className="col-xs-8 col-sm-8 col-md-8">

            {this.state.confirmSubscription &&
              <div className='confirm_subscription'>
                  <h3>Konfirmimi i abonimit me pagesë</h3>
                  <div style={{marginLeft:'20px'}}>
                    <label style={{fontSize:'14px'}}><b>1. Konfirmoni pakon e abonimit:</b><br/></label>
                      <br/><select style={{fontSize:'13px'}} className="form-control" name="pako"  value={confirmedPakoFinalValue} onChange={this.handleConfirmSubscriptionChange}>

                        <option value="1">Pako Për Individ - 15 EUR/muaj (pa TVSH)</option>
                        <option value="2">Pako Për Kompani të Vogla - 23 EUR/muaj (pa TVSH)</option>
                        <option value="3">Pako për Kompani të Mesme dhe të Mëdha - 30 EUR/muaj (pa TVSH)</option>
                      </select>

                    <small style={{marginLeft:'17px'}}>Fakturimi bëhet çdo 3 muaj. <a href="/pricing" target="_blank">Klikoni këtu</a> për përshkrimin e secilës pako.</small><br />
                        {this.state.isLoading &&
                          <div className="loading_div"><img src='/loading.gif' alt='loading...' /></div>
                        }
                    <label style={{fontSize:'14px',marginTop:'10px'}}><b>2. Dërgoni konfirmimin</b></label>
                    <br/><input type="submit" disabled={this.state.confirmButtonDisabled} value="Konfirmo" onClick={this.handleConfirmSubscription} className="btn btn-info btn-block" style={{width:'50%'}} />
                      { this.state.confirmSubscriptionText.length > 0 &&
                          <span className='confirm_subscription_text' style={{color:this.state.confirmSubscriptionTextColor}}>{this.state.confirmSubscriptionText}</span>
                      }

                  </div>

              </div>
            }



              <div className="form-group">
              <label><b>Email:*</b></label>
                <input disabled={true} type="email" name="email" className="form-control input-sm" placeholder="Email Adresa"  value={this.state.formData.email} required/>
              </div>

              <div className="form-group-sm">
                <label style={{marginTop:'10px',borderBottom:'3px solid yellow'}}><b>Kategoria kryesore e tenderave: </b>
                  <br/>
                </label>

                <select className="form-control" name="category1" value={this.state.formData.category1} onChange={this.handleChange}>
                      {this.state.categories.map(category => (
                          <option key={category.cat_id} value={category.cat_id}>{category.cat_name}</option>
                       ))}
                </select>


                <br/><label style={{marginTop:'10px'}}><b>Kategoria e dytë(opcionale):</b>
                </label>

                <select className="form-control" name="category2" value={this.state.formData.category2} onChange={this.handleChange}>
                        <option>---</option>
                        {this.state.categories.map(category => (
                          <option key={category.cat_id} value={category.cat_id}>{category.cat_name}</option>
                       ))}
                </select>

                <br/><label style={{marginTop:'10px'}}><b>Kategoria e tretë(opcionale):</b>
                </label>

                <select className="form-control" name="category3" value={this.state.formData.category3} onChange={this.handleChange}>
                        <option>---</option>
                        {this.state.categories.map(category => (
                          <option key={category.cat_id} value={category.cat_id}>{category.cat_name}</option>
                       ))}
                </select>
              </div>

            </div>

            <div className="col-xs-8 col-sm-8 col-md-8">
              <div className="form-group">
                <label style={{borderBottom:'3px solid yellow'}}><br /><b>Të dhënat e kompanisë/individit:</b></label>
                <br /><label className="small_label">Emri i kompanisë/Individit*</label>
                <input type="text" name="company_name" className="form-control input-sm" placeholder="Emri i kompanisë/Individit*" onChange={this.handleChange} value={this.state.formData.company_name} />
                {errors.company_name.length > 0 &&
                    <span className='error'>{errors.company_name}</span>}
              </div>
            </div>

            <div className="col-xs-6 col-sm-6 col-md-7">
              <div className="form-group">
              <label className="small_label">Numri Fiskal/NIPT</label>
                <input type="text" name="fiscalnr_nipt" className="form-control input-sm" placeholder="Numri Fiskal/NIPT" onChange={this.handleChange}  value={this.state.formData.fiscalnr_nipt} />
              </div>
            </div>

            <div className="col-7">
              <div className="form-group">
              <label className="small_label">Adresa e kompanisë</label>
                <input type="text" name="business_address" className="form-control input-sm" placeholder="Adresa e kompanisë" onChange={this.handleChange}  value={this.state.formData.business_address} />
              </div>
            </div>

            <div className="col-xs-6 col-sm-6 col-md-7">
              <div className="form-group">
              <label className="small_label">Kontakt personi</label>
                <input type="text" name="contact_person" className="form-control input-sm" placeholder="Kontakt personi" onChange={this.handleChange}  value={this.state.formData.contact_person} />
              </div>
            </div>

            <div className="col-7">
                <div className="form-group">

                  {this.state.showBottomPackages &&
                    <div>
                      <label style={{borderBottom:'3px solid yellow'}}><b>Pakoja e abonimit:</b> <small>(<a href="/pricing" target="_blank">klikoni këtu</a> për përshkrimin e secilës pako)</small></label>
                        <br/><select  style={{fontSize:'13px'}} className="form-control" name="pako"  value={this.state.formData.pako} onChange={this.handleChange}>

                          {this.state.formData.pako === 0 &&
                            <option value="0">1 muaj falas - periudhë provuese</option>
                          }

                          <option value="1">Pako Për Individ - 15 EUR/muaj (pa TVSH)</option>
                          <option value="2">Pako Për Kompani të Vogla - 23 EUR/muaj (pa TVSH)</option>
                          <option value="3">Pako për Kompani të Mesme dhe të Mëdha - 30 EUR/muaj (pa TVSH)</option>
                        </select>
                      </div>
                    }

                    <div className="col-xs-8 col-sm-8 col-md-8">
                    <br/><p style={{textDecoration:'underline',cursor:'pointer'}} onClick={this.toggleChangePassword}>Ndërroni passwordin</p>

                      {this.state.changePassword &&
                      <div className="form-group">
                      <label>Passwordi vjetër:</label>
                        <input type="password" name="password" className="form-control input-sm"  onBlur={this.validateField} onChange={this.handleChange} value={this.state.formData.password} />
                      {errors.password.length > 0 &&
                          <span className='error'>{errors.password}</span>}

                      <br /><label>Passwordi ri:</label>
                        <input type="password" name="new_password" className="form-control input-sm"  onBlur={this.validateField} onChange={this.handleChange} value={this.state.formData.new_password} />
                      {errors.new_password.length > 0 &&
                          <span className='error'>{errors.new_password}</span>}

                      <br /><label>Konfirmo passwordin e ri:</label>
                        <input type="password" name="confirm_password" className="form-control input-sm"  onBlur={this.validateField} onChange={this.handleChange} value={this.state.formData.confirm_password} />
                      {errors.confirm_password.length > 0 &&
                          <span className='error'>{errors.confirm_password}</span>}

                      </div>}

                    </div>

                    {this.state.successMessage.length > 0 &&
                        <span className='success_message'>{this.state.successMessage}<br /></span>
                    }

                    <br/><input type="submit" value="Ruaj ndryshimet" onClick={this.handleSubmit} className="btn btn-info btn-block" />


                </div>
            </div>



            <Modal show={this.state.showModal} onHide={this.handleClose}>

              <Modal.Header closeButton>
                <Modal.Title>Problem(s) submitting your form:</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <ul>
                    {Object.keys(errors).map(function(keyName, keyIndex) {
                        if(errors[keyName].length > 0){
                          return <li key={keyIndex}>{errors[keyName]}</li>
                        } else {
                          return null
                        }
                    })}
                </ul>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
              </Modal.Footer>

            </Modal>

      </div>

    );
  }
}

export default withRouter(Profile);
