import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import CountUp from 'react-countup';
import ls from 'local-storage'
import Loading from '../components/Loading.js'
import TableLanding from '../components/TableLanding.js'
import Modal from 'react-responsive-modal';
import { Button } from 'react-bootstrap';
import ga from 'utils/ga';

class LandingPage extends Component {
  constructor(props) {
    super(props);
    //let selectedPackage = 2

    this.state = {
        params: false,
        allCategoriesTotal: 0,
        category:'',
        categoryText:'',
        activeData: { tenders: [] },
        isLoading: false,
        showModal: false,
        categories: [],
        subscriptionStatus:''
    };


    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);

  }

  handleClose(){
    this.setState({showModal:false})
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ isLoading: true });

    axios.get('last_30_days_stats/'+value)
      .then(res => {
        const resp = res.data;
        this.setState({ activeData: resp, isLoading: false });
      }).catch(error => {
        alert(error)
        this.setState({ isLoading: false });
      })

    this.setState({
      [name]: value, categoryText: value
    });
  }

  componentDidMount() {

    if(this.props.locationData.search){
        const queryString = require('query-string');
        const parsedParams = queryString.parse(this.props.locationData.search);
        if(parsedParams.kategoria) {
          axios.get('last_30_days_stats/'+parsedParams.kategoria)
          .then(res => {
            const resp = res.data;
            this.setState({ category:parsedParams.kategoria, activeData: resp, params: parsedParams, isLoading: false });
          }).catch(error => {
            alert(error)
            this.setState({ isLoading: false });
          })
        } else {
          this.setState({params: parsedParams}) 
        }
        
    }

    axios.get('last_30_days_total_value')
      .then(res => {
        const resp = res.data;
        this.setState({ allCategoriesTotal: resp});
      })

    axios.get('categories_list')
      .then(res => {
        const categories = res.data;
        this.setState({ categories: categories });
      })

      ga.set({ page: "Landing Page" }); // Update the user's current page
      ga.pageview(window.location.pathname); // Record a pageview for the given page
    
      //setTimeout(() => this.setState({ showModal: true }), 180000)
  }


  render() {
    //const {errors} = this.state;
    //axios.get('subscribe') // call endpoint for analytics purposes only
    ls.set('lastCategory', this.state.category)
    const { showModal } = this.state;
    return (

      <div className="row" style={{marginLeft:'25px'}}>


            <div className="col-md-8">
              <div className="form-group">
              <span className='category_number' style={{borderBottom:'3px solid yellow'}}>
                          <CountUp
                            end={this.state.allCategoriesTotal}
                            duration={1.5}
                            separator=","
                          /> EUR tendera
                        </span>
                        <span>
                             në 30 ditët e fundit
                        </span><br />


                        {this.state.isLoading &&
                          <Loading />
                        }

                        <p style={{fontSize:'17px',marginTop:'10px'}}>Shiko sipas industrisë:</p>
                        <select style={{marginBottom:'10px',marginTop:'10px'}} className="form-control" name="category" value={this.state.category} onChange={this.handleChange}>
                            <option key='random0' value=''>---></option>
                            {this.state.categories.map(category => (
                                <option key={category.cat_id} value={category.cat_id}>{category.cat_name}</option>
                            ))}
                        </select>


                        {this.state.activeData.tenders.length > 0 ?
                             <> 
                              {this.state.activeData.active_tenders_value > 0 && 
                              <div>
                                <span className='category_number' style={{borderBottom:'3px solid yellow'}}>
                                {this.state.activeData.active_tenders_value.toLocaleString()} EUR tendera
                                  </span>                        
                                  <span>
                                    në 30 ditët e fundit
                                  </span>
                                    {/* <Link to={'historiku?kategoria='+this.state.category} style={{marginTop:'10px', display:'block', color:'#000', fontSize:'12px'}}> 
                                      Klikoni këtu për tenderat e vitit 2020 dhe 2019</Link>    */}                               
                               </div>  
                               }
                               <TableLanding tableTitle='' tenders={this.state.activeData.tenders} tableType='small_table_div' loggedIn={this.props.loggedIn} />
                             </>  
                              :
                                 <span style={{marginLeft:'15px'}}>...</span>
                              
                        }    


            </div>
            </div>


            <div className='col-md-4'>             
                  
            <div className="modal_content">
              <Modal
                open={showModal}
                showCloseIcon={false}
                focusTrapped={false}
                onClose={this.handleClose}
                center
                closeOnOverlayClick={false}
                closeOnEsc={false}               
                styles={{ overlay: { background: "#000", padding:'40px' } }}>

                <h4 style={{borderBottom:'3px solid yellow'}}>A doni me e rritë fitimin tuaj në vitin 2020?</h4>
                <div style={{textAlign:'center'}}>

                <Button variant="warning" onClick={this.handleClose} href={'regjistrimi?kategoria='+this.state.category}>
                  Po
                </Button>&nbsp;
                <Button variant="warning" onClick={this.handleClose}>
                  Jo
                </Button>
                </div>
              </Modal>
            </div>


            </div>


    </div>




    );
  }
}

export default withRouter(LandingPage);
