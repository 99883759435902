import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-responsive-modal';

import CategoryStatsAndTenders from '../components/CategoryStatsAndTenders.js'
import Loading from '../components/Loading.js'

import ga from 'utils/ga';


class TendersPublic extends Component {
	constructor(props) {
		super(props);
		this.topOfPageRef = React.createRef()
		this.state = {
				tenderInsideInfo:{
						link_data: {
							external_url: ''}
					},
				isLoading: true,
				categoryStats: [],
				categoryParams: [
					{nrTendersToShow: 10, buttonState: 'more'},
					{nrTendersToShow: 5, buttonState: 'more'},
					{nrTendersToShow: 5, buttonState: 'more'}
				],
				sendToTopOfPage: false,
				daysToExpire: 0,
				today: new Date(),
				textToCopySample: "Të nderuar,\n\nLuteni të na dërgoni dosjen e tenderit me titullin: \"TITLE\" me numër prokurimi: \"PROCURE\".\n\nJu faleminderit",
				textToCopyActive: "",
				copyPasteButtonText: "Kopjo tekstin për kërkesë të dosjes së tenderit*",
				copyPasteButtonTextInitial: "Kopjo tekstin për kërkesë të dosjes së tenderit*",
				copyPasteButtonTextCopiedSuccessfully: "Teksti u kopjua, i gatshëm për copy-paste",
				showModal: false,
				seconds:3,

		}


		this.goBack = this.goBack.bind(this);
		this.getTenderData = this.getTenderData.bind(this);
		this.copyFromTextArea = this.copyFromTextArea.bind(this);
		this.updateCopyPasteText = this.updateCopyPasteText.bind(this);
		this.setCategoryAndNrTenders = this.setCategoryAndNrTenders.bind(this);
	}

	copyFromTextArea(){
		this.setState({ copyPasteButtonText: this.state.copyPasteButtonTextCopiedSuccessfully })
		axios.get('analytics/'+this.state.tenderInsideInfo.md5shi)
	}

	updateCopyPasteText(){
		let textTemplate = this.state.textToCopySample
		const tenderInsideInfo = this.state.tenderInsideInfo
		textTemplate = textTemplate.replace('TITLE',tenderInsideInfo.Title).replace('PROCURE',tenderInsideInfo.Procure)
		this.setState({ textToCopyActive: textTemplate, copyPasteButtonText: this.state.copyPasteButtonTextInitial })
		//alert(textTemplate)
	}

	setCategoryAndNrTenders(categoryIndex,nrTenders, buttonState){
			this.setState((prevState) => {
				let newState = [...prevState.categoryParams]
				newState.map((categoryParam, index) => {
							if(index === categoryIndex){
							 return	newState[index] = {nrTendersToShow:nrTenders,buttonState:buttonState}
							} else {
							 return	newState[index] = {nrTendersToShow:5,buttonState:'more'}
							}
				})
				return { categoryParams: [ ...newState ]  }
			})
	}

	goBack(){
		this.props.history.goBack();
	}

	startInterval(){
		this.myInterval = setInterval(() => {
			if(this.state.seconds === 0) {
				window.clearInterval(this.myInterval);
				this.props.history.push('/subscribe?welcomeText=true&tid='+this.props.location.pathname.split('/')[2]);
			} else {
				this.setState(({ seconds }) => ({
					seconds: seconds - 1
				  }))			
			}
	
		  }, 1000)
		  	  
	}


	componentDidMount() {

                // Tender Inside Page
				if(this.props.match.params.id.length > 19) {
					axios.get('https://api.bic-ks.com/public/tender/details/'+this.props.match.params.id)
						.then(res => {
							const tender = res.data;
							this.setState({ tenderInsideInfo: tender, isLoading: false });
							this.updateCopyPasteText()
						})
				}
				// Tenders Main Page
				else {
				axios.get('tenders/main')
					.then(res => {
						const tender = res.data; // most_recent_tender in primary category
						this.setState({ tenderInsideInfo: tender, isLoading: false });
						this.updateCopyPasteText()
					})
				}

	ga.set({ page: "Tenders Public" }); // Update the user's current page
	ga.pageview(window.location.pathname); // Record a pageview for the given page 

	//setTimeout(() => this.setState({ showModal: true }), 1000)			
	setTimeout(() => 
		{
			this.setState({ showModal: true })
			this.startInterval()
		}
		, 1000)		
	}

 componentDidUpdate(){
	 if(this.state.sendToTopOfPage) {
		 	this.topOfPageRef.current.scrollIntoView({ behavior: 'smooth' })	// go to beginning of tender
			this.setState({ sendToTopOfPage: false });
		}
	
		
 }

 getTenderData(md5) {

	 this.setState({ isLoading: true });
	 axios.get('tender/details/'+md5)
		 .then(res => {
			 const tender = res.data;
			 this.setState({ tenderInsideInfo: tender, isLoading: false, sendToTopOfPage:true });
			 this.updateCopyPasteText()
		 })

 }



	render() {
		const tenderInsideInfo = this.state.tenderInsideInfo


		let winnerNameLabel = ''
		if(tenderInsideInfo.Winner_Name){
				if(tenderInsideInfo.Winner_Name.includes(',') || tenderInsideInfo.Winner_Name.includes(';')) {
					winnerNameLabel = 'Kompanitë fituese'
				} else {
					winnerNameLabel = 'Kompania fituese'
				}
		 }




		return (

			<div className='col-12 body-and-right-menu' style={{marginTop:'0px'}}>

			<div ref={this.topOfPageRef}></div>

				<div className='row'>
						<div className='col-12 col-md-8'>
							<h3>{ tenderInsideInfo.Title }
								{tenderInsideInfo.Value_Of_Contract &&
									<span><br/>
										€{tenderInsideInfo.integer_value_pretty}
									</span>
								}
							</h3>


					<div className='table_div'>
						<div className='table_decorator'>&nbsp;</div>

						{this.state.isLoading &&
							<Loading />
						}

							<table className="table table-hover table-bordered inside-tender" style={{marginBottom:'0px'}}>
								<tbody>

								{tenderInsideInfo.Winner_Name  &&
									<tr><td className='field_description'><span>{winnerNameLabel}:	</span></td><td><span>{tenderInsideInfo.Winner_Name}</span></td></tr>
								}

									<tr><td className='field_description'><span>Autoriteti Kontraktues:</span></td><td><span>{ tenderInsideInfo.Solicitor }</span></td></tr>
									<tr><td className='field_description'><span>Data e skadimit:	</span></td><td><span>{ tenderInsideInfo.expiry_date_text } - <span style={{color:'red',fontWeight:'bold'}}>{ tenderInsideInfo.remaining_days_text }</span></span></td></tr>
									<tr><td className='field_description'><span>Dokumenti i njoftimit:</span></td><td><span><a href={tenderInsideInfo.document_url} target='_blank' rel='noopener noreferrer'><b>Shikoje publikimin</b></a></span></td></tr>
									<tr><td className='normal_field' style={{borderTop:'3px solid yellow'}}><span>Nr. i Prokurimit:	</span></td><td style={{borderTop:'3px solid yellow'}}><span>{ tenderInsideInfo.Procure }</span></td></tr>
									<tr><td className='normal_field'><span>Data e publikimit:	</span></td><td><span>{ tenderInsideInfo.Published_Date }</span></td></tr>
									<tr><td className='normal_field' style={{borderBottom:'3px solid yellow'}}><span>Lloji i njoftimit:	</span></td><td style={{borderBottom:'3px solid yellow'}}><span>{ tenderInsideInfo.Notice_Type }</span></td></tr>




								</tbody>
							</table>
						<div className='table_decorator'>&nbsp;</div>



					</div>

						</div>

						{/* Category Stats - Right div */}
						<div className='col-12 col-md-4'>
							{this.state.categoryStats.map((category, index) => {
									   if(category){
											 return <CategoryStatsAndTenders key={index} currentTenderMD5={tenderInsideInfo.md5shi} index={index} stats={this.state.categoryStats[index]} categoryParams={this.state.categoryParams} setCategoryAndNrTenders={this.setCategoryAndNrTenders}  getTenderData={this.getTenderData} />
										 } else {
											 return false
										 }
									}
							)}

							<div className="modal_content">
										<Modal
											open={this.state.showModal}
											showCloseIcon={false}
											focusTrapped={false}
											onClose={this.handleClose}
											center
											closeOnOverlayClick={false}
											closeOnEsc={false}               
											styles={{ overlay: {  padding:'40px' } }}>

											<div style={{textAlign:'center'}}><h1>{this.state.seconds}
											</h1></div>
											<h5 style={{fontSize:"15px",borderBottom:'0px'}}>
												Për t'i shikuar tenderat duhet të vazhdoni dhe të regjistroheni në platformën tonë.</h5>

										</Modal>
										</div>
						</div>

				</div>

			</div>

		);
	}

}



export default withRouter(TendersPublic);
