import React, { Component } from "react";
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';

class TableLanding extends Component {
  constructor() {
    super();

    this.state = {
      loggedIn: 'test'
    }
  }

  
  render() {

    var sortedTenders = this.props.tenders.sort((a, b) => (a.integer_values > b.integer_values) ? -1 : 1)  
  //var defaultUrl = '/subscribe?welcomeText=true'
  sortedTenders.forEach(function (tender) {
    tender.URL = '/tenders/'+tender.md5shi
    //tender.subscribeURL = defaultUrl
    tender.subscribeURL = '/tendersPublic/'+tender.md5shi
    });

    return (
      <div className='col-12'  style={{marginTop:'10px',paddingLeft:'0px'}} >

         {this.props.tableTitle.length > 0 &&
            <div className='home_table_decorator'>
              <span style={{paddingLeft:'12px',fontSize:'12px'}}>{this.props.tableTitle}</span>
            </div>
         }

         
          <div className='home_table_div'>
                <table className="table table-hover table-bordered home_table">
                  <tbody>
                      {this.props.tenders.length ?
                        sortedTenders.map((tender, index) => (
                              <tr key={index}>
                                 <td style={{width:'70%'}}>
                                 <span><Link to={ this.props.loggedIn ? tender.URL : tender.subscribeURL } className='black_link'> {tender.Title}
                                &nbsp;--></Link></span><br />

                                   </td>
                                 <td>
                                  
                                  {tender.integer_values > 0 &&
                                   <span>
                                     <CountUp
                                       end={tender.integer_values}
                                       duration={2}
                                       separator=","
                                     />€
                                     </span>
                                  }  
                                     
                                  </td>
                               </tr>

                         ))
                         :
                         ('loading...')
                      }

                  </tbody>
                </table>

          </div>

      </div>
    )
  }

}

export default TableLanding;
