import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import ls from 'local-storage'
import ga from 'utils/ga';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i); // eslint-disable-line

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
        // form fields
        email: '',
        password:'',
        errors: {
          email: '',
          password: ''
        }
      }
  }


  getErrorLabels = () => {
    const labels = {'email':'Email is not valid!','password':'Password has to be at least 6 characters'}
    return labels
  }

  logInUser() {
    this.props.logInUser();
  }


  handleOnFocus = (event) => {
      // reset error when field is selected by user
      event.preventDefault();
      const { name, value } = event.target;
      let errors = this.state.errors;

      if(name === 'email') {
        errors.email = ''
      } else if(name === 'password'){
        errors.password = ''
      }

      this.setState({errors, [name]: value});
  }

  validateField = (event) => {
      event.preventDefault();
      const { name, value } = event.target;
      let errors = this.state.errors;
      let errorLabels = this.getErrorLabels();

      if(name === 'email') {
        errors.email = validEmailRegex.test(value) ? '': errorLabels.email
      } else if(name === 'password'){
        errors.password = value.length >= 6 ? '': errorLabels.password;
      }

      this.setState({errors, [name]: value});
  }


  handleSubmit = (event) => {
    event.preventDefault();

    let formData = this.state;
    let errorLabels = this.getErrorLabels();
    let errors = this.state.errors;
    var errorsFound = false;

    // check Form fields for errors
    if(!validEmailRegex.test(formData['email'])) {
      errors.email = errorLabels.email;
      errorsFound = true;
     }

     if(formData['password'].length < 6){
       errors.password = errorLabels.password;
       errorsFound = true;
     }
    this.setState({errors});


    if(!errorsFound) {
      const { email, password } = this.state;
      axios.post('login', {
             email,
             password
         })
        .then(res => {
          ls.set('jwtToken', res.data.jwt_token);
          ls.set('expiryDate', res.data.expiry_date);
          ls.set('accountExpiryDate', res.data.account_expiry_date);
          ls.set('adminAccess', true);
          this.logInUser()
          this.props.history.push('/tenders/:id')

        }).catch(error => {
          alert('errori:'+error);
        });
    }


  }

  componentDidMount = () => {
    ga.set({ page: "Login" }); // Update the user's current page
    ga.pageview(window.location.pathname); // Record a pageview for the given page
  }


  render() {
  const {errors} = this.state;
  axios.get('login') // call endpoint for analytics purposes only

    return (

          <div className="row" style={{marginLeft:'0px'}}>
                        <div className="col-md-6 login-form">

                            <h3 style={{marginLeft:'0px'}}>Kyçu/Login</h3>

                            <form>
                                <div className="form-group">

                                    <input type="email" className="form-control" name="email" placeholder="Your Email *"  onBlur={this.validateField}  onSelect={this.handleOnFocus} required/>
                                    {errors.email.length > 0 &&
                                        <span className='error'>{errors.email}</span>}
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" name="password" placeholder="Your Password *" onBlur ={this.validateField}  onSelect={this.handleOnFocus} required/>
                                    {errors.password.length > 0 &&
                                        <span className='error'>{errors.password}</span>}
                                </div>
                                <div className="form-group">
                                    <input type="submit" className="btnSubmit" value="Kyçu/Login"  onClick={this.handleSubmit} />
                                </div>
                                <div className="form-group">
                                    <a href="https://api.bic-ks.com/account/reset_password" className="ForgetPwd">Keni harruar passwordin?</a>
                                </div>
                            </form>

                          </div>
            </div>

    );

  }

}



export default withRouter(Login);
