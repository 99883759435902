import React, { Component } from "react";
import { Link } from 'react-router-dom';

class TableSmall extends Component {

  render() {
    return (
      <div className={this.props.tableType}>
        <div className='small_table_decorator'>&nbsp;</div>
          <table className="table table-hover table-bordered" style={{marginBottom:'0px'}}>
            <tbody style={{width:'100%'}}>
                {this.props.searchResults.map(tender => (
                  <div className='small_table_row'  key={tender.result_id}>

                      <tr key={tender.result_id}>
                         <td>
                            <Link to={ '/tenders/'+tender.md5 } target="_blank">{tender.result_id}. {tender.Title}</Link>
                         </td>
                      </tr>

                      <tr>
                         <td>
                           <span style={{fontSize:'8px'}}>
                             {tender.integer_values > 0 &&
                               <span>{tender.integer_values.toLocaleString()} € | </span>
                             }

                             {tender.solicitor}

                             {this.props.showRemainingDays &&
                              <span style={{fontSize:'10px',color:'red'}}> | {tender.remaining_days_text}</span>
                             }
                              - {tender.category}

                            </span>
                          </td>
                       </tr>

                  </div>

                 ))
                }
            </tbody>
          </table>
        <div className='small_table_decorator'>&nbsp;</div>
      </div>
    )
  }

}

export default TableSmall;
