import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import Loading from '../components/Loading.js'
import TableSmall from '../components/TableSmall.js'
import axios from 'axios';
import ga from 'utils/ga';

class Search extends Component {

  constructor(props){
      super(props);
      let activeSearchString = this.props.searchString? this.props.searchString : this.props.match.params.id
      if(activeSearchString === ':id') {
        activeSearchString = ''
      }

      this.state = {
        searchString: activeSearchString,
        searchCategory: '',
        searchInstitution: '',
        searchResults: [],
        paginationItems:[],
        userHistory: [],
        totalResults: 0,
        nrPages: 0,
        categories: [],
        institutions: [],
        isLoading: false,
        userHistoryLoading: true,

      }

    this.changePageNr = this.changePageNr.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchSearchResults = this.fetchSearchResults.bind(this);
   }


   changePageNr(pageNumber) {
     this.fetchSearchResults(pageNumber);
     this.setState({pageNr: pageNumber, isLoading: true});


   }

   handleSubmit(event) {
     const query = this.state.searchString;
      this.setState({isLoading: true, pageNr: 1});
      this.fetchSearchResults(1);
      if(query.length >= 3) {
        this.props.history.push('/search/'+query)
      }

   }

   handleChange(event) {
     const target = event.target;
     const value = target.type === 'checkbox' ? target.checked : target.value;
     const name = target.name;

     this.setState({
       [name]: value
     });
   }

  fetchSearchResults(pageNumber) {
    axios.post('search',
    {
        searchString: this.state.searchString,
        searchCategory: this.state.searchCategory,
        searchInstitution: this.state.searchInstitution,
        pageNr: pageNumber,
      })
      .then((res, state) => {
        const results = res.data;
        if(results.totalCount === 0) {
          results.tenders.push({'result_id':'-','Title':'Nuk janë gjetur rezultate','md5':'---','integer_values':0})
        }

        this.setState({ searchResults: results.tenders, totalResults: results.totalCount, nrPages: Math.ceil(results.totalCount/10), pageNr: this.state.pageNr, isLoading:false });
        this.fillPaginationItems();
      }).catch(error => {
        alert('Ka probleme me ekzekutimin e kërkimit.')
      })
  }


  componentDidMount() {
    axios.get('categories_list')
      .then(res => {
        const categories = res.data;
        this.setState({ categories });
      })

    axios.get('get_institutions')
      .then(res => {
        const institutions = res.data;
        this.setState({ institutions });
      })

    axios.get('get_user_history')
      .then(res => {
        const userHistory = res.data;
        this.setState({ userHistory, userHistoryLoading: false });
      })

      ga.set({ page: "Search" }); // Update the user's current page
      ga.pageview(window.location.pathname); // Record a pageview for the given page

  }

   fillPaginationItems(){
     let newItems = [];
     let pageNumbers = [1,2,3,4,5,6,7,8,9,10];
     pageNumbers.map(pageNr => (
       newItems.push(<li key={pageNr}><button  onClick={ () => this.changePageNr(pageNr) } className={parseInt(this.state.pageNr,10) !== pageNr? 'page-link' : 'page-link active-pagination-item' }>Faqe {pageNr}</button></li>)
     ))
     this.setState({paginationItems: newItems });
   }


  render() {
    return (
          <div className="row" style={{marginLeft:'0px'}}>
                <div className='col-12 col-md-7' style={{marginLeft:'20px'}}>
                      <h5>Kërkoni sipas parametrave:</h5>

                      <label className='search_label'>Titulli:</label>
                      <input type="text" name="searchString" value={this.state.searchString} onChange={this.handleChange}  className="form-control input-sm" />

                      <label className='search_label'>Kategoria:</label>
                      <select className="form-control" name="searchCategory" value={this.state.searchCategory} onChange={this.handleChange}>
                            <option key='0' value='' defaultValue>Të gjitha</option>
                            {this.state.categories.map(category => (
                                <option key={category.cat_id} value={category.cat_id}>{category.cat_name}</option>
                             ))}
                      </select>

                      <label className='search_label'>Institucioni:</label>
                      <select className="form-control" name="searchInstitution" value={this.state.searchInstitution} onChange={this.handleChange}>
                            <option key='0' value='' defaultValue>Të gjitha</option>
                            {this.state.institutions.map(institution => (
                                <option key={institution.id} value={institution.primary_name} dangerouslySetInnerHTML={{__html: institution.primary_name}}></option>
                             ))}
                      </select>

                      {this.state.isLoading &&
                        <Loading />
                      }

                      <br/><input type="submit" value="Kërko" onClick={ () => this.handleSubmit() } className="btn btn-info btn-block" style={{width:'50%'}}/>

                      {/*<!-- pagination -->*/}
                      <hr />
                      <nav aria-label="Page navigation">
                        <ul className="pagination pagination-sm" style={{marginLeft:'20px'}}>
                           {this.state.paginationItems}
                        </ul>
                      </nav>

                    {this.state.searchResults.length > 0 &&
                      <TableSmall searchResults={this.state.searchResults} tableType='small_table_div' showRemainingDays={true} />
                    }

                </div>

                <div className='col-12 col-md-4'>
                  <div className='user_history'>
                      <h5>Tenderët e vizituar</h5>
                      {this.state.userHistoryLoading &&
                        <Loading />
                      }
                      {this.state.userHistory.length > 0 &&
                        <TableSmall searchResults={this.state.userHistory} tableType='small_table_div' />
                      }
                   </div>
                </div>

          </div>
    );
  }

}

export default withRouter(Search);
