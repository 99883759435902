import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import ga from 'utils/ga';
//import ls from 'local-storage'

class Logout extends Component {

   logOutUser() {
     this.props.logOutUser();
   }

  componentDidMount() {
    setTimeout(
      function() {
        axios.get('logout') // call endpoint for analytics purposes only
        this.logOutUser()
        this.props.history.push('/home')
      }
      .bind(this),
      1000
    );

    ga.set({ page: "Logout" }); // Update the user's current page
    ga.pageview(window.location.pathname); // Record a pageview for the given page 
  }

  render() {


    return (
      <div className="row">
        <div className="col-md-6" style={{textAlign:'center'}}>
            <p>Duke dalur...</p>
          </div>
        </div>
    );
  }

}



export default withRouter(Logout);
