import React, { Component } from "react";
import Home from './Home.js'
import Pricing from './Pricing.js'
import Subscribe from './Subscribe.js'
import LandingPage from './LandingPage.js'
import HistoryPage from './HistoryPage.js'
import Profile from './Profile.js'
import Login from './Login.js'
import Logout from './Logout.js'
import Tenders from './Tenders.js'
import TendersPublic from './TendersPublic.js'
import Regjistrimi from './Regjistrimi.js'
import Search from './Search.js'
import Faq from './Faq.js'
import TermsAndConditions from './TermsAndConditions.js'


class Routes extends Component {
  constructor(props){
    super(props);
    this.state = {
       routes: []
    }
   }

render() {
  return (
      <p>...</p>
        );
 }
}

const homeRoute = {
      path: '/home',
      name: 'Fillimi',
      component: Home,
      showInMenu: true
    }
const termsAndConditionsRoute = {
      path: '/terms',
      name: 'Terms and Conditions',
      component: TermsAndConditions,
      showInMenu: false
    }



export const privateRoutes = [
    homeRoute,
    termsAndConditionsRoute,
    {
      path: '/tenders/:id',
      component: Tenders,
      name: 'Tenderat',
      requiresAuth: true,
      showInMenu: true
    },
    {
        path: '/search/:id',
        name: 'Kërko --->',
        component: Search,
        requiresAuth: false,
        showInMenu: true
    },
    {
      path: '/profile',
      component: Profile,
      name: 'Profili',
      requiresAuth: true,
      showInMenu: true
    },
    {
      path: '/logout',
      component: Logout,
      name: 'Dil',
      requiresAuth: true,
      showInMenu: true
    },
    {
      path: '/pricing',
      component: Pricing,
      name: 'Çmimorja',
      requiresAuth: false,
      showInMenu: false
    },
    {
       path: '/subscribe',
       component: Subscribe,
       name: 'Regjistrohu',
       requiresAuth: false,
       showInMenu: false
     },{
      path: '/historiku',
      component: HistoryPage,
      name: 'Historiku',
      requiresAuth: false,
      showInMenu: false
    },
     {
       path: '/landing',
       component: LandingPage,
       name: 'Landing Page',
       requiresAuth: false,
       showInMenu: false
     },
     {
       path: '/regjistrimi',
       component: Regjistrimi,
       name: 'Regjistrimi',
       requiresAuth: false,
       showInMenu: false
     }
  ]

  export const publicRoutes = [
     homeRoute,
     termsAndConditionsRoute,
     {
       path: '/pricing',
       component: Pricing,
       name: 'Çmimorja',
       requiresAuth: false,
       showInMenu: true
     },
     {
        path: '/subscribe',
        component: Subscribe,
        name: 'Regjistrohu',
        requiresAuth: false,
        showInMenu: true
      },
      {
        path: '/faq',
        component: Faq,
        name: 'Pyetje të shpeshta',
        requiresAuth: false,
        showInMenu: true
      },
      {
        path: '/login',
        component: Login,
        name: 'Kyçu/Login',
        requiresAuth: false,
        showInMenu: true
      },
      {
        path: '/search/:id',
        name: 'Search',
        component: Search,
        requiresAuth: false,
        showInMenu: false
      },{
        path: '/historiku',
        component: HistoryPage,
        name: 'Historiku',
        requiresAuth: false,
        showInMenu: false
      },
      {
        path: '/landing',
        component: LandingPage,
        name: 'Landing Page',
        requiresAuth: false,
        showInMenu: false
      },
      {
        path: '/tendersPublic/:id',
        component: TendersPublic,
        name: 'Tenders Public Page',
        requiresAuth: false,
        showInMenu: false
      },
      {
        path: '/regjistrimi',
        component: Regjistrimi,
        name: 'Regjistrimi',
        requiresAuth: false,
        showInMenu: false
      }
    ]

    

    export default {
        Routes
    }
