import React, { Component } from "react";
import { Route, withRouter } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import ls from 'local-storage'

/**
 * - Expired Modal Appears when user is logged in, accountExpiryDate is less than today and the page is not /profile
 * - Handle Expired Modal in constructor
 * - Handle Expired Modal on route change
 * - Handle Conversion Modal on componentDidMount
 * - Load routes from props
 */

class Body extends Component {

  constructor(props) {
    super(props)

    let openModal = false;
    let modalTitle = '';
    let modalDescription = '';
    let modalCloseButton = true;

    const today = new Date();
    //alert(ls.get('source'))

    if(!ls.get('source')) {
      // alert('no cookie, attempting to set')
      const queryString = require('query-string');
      const parsedParams = queryString.parse(this.props.history.location.search);
      let source = 'website';
      if(parsedParams.source) {
        source = parsedParams.source;
      }       
      ls.set('source', source)
    }

    // Handle Expired Modal
   if(this.props.loggedIn && Date.parse(ls.get('accountExpiryDate')) < today  && !this.props.history.location.pathname.includes('/historiku') && this.props.history.location.pathname !== '/profile' && this.props.history.location.pathname !== '/pricing' && this.props.history.location.pathname !== '/logout') {
      openModal = true;
      modalTitle = 'Qasja juaj ka skaduar!';
      modalDescription = '<a href="/profile?confirm_subscription=true">Klikoni këtu për të vazhduar</a> me abonim të rregullt në platformën tonë.';
      modalCloseButton = false;
    }
    // Handle Expired Modal END

    this.state = {
       openModal: openModal,
       modalTitle: modalTitle,
       modalDescription: modalDescription,
       modalCloseButton: modalCloseButton
    }


  this.logInUser = this.logInUser.bind(this);
  this.logOutUser = this.logOutUser.bind(this);
  this.onOpenModal = this.onOpenModal.bind(this);
  this.onCloseModal = this.onCloseModal.bind(this);
  }



  logInUser() {
    this.props.logInUser();
  }

   logOutUser() {
     this.props.logOutUser();
   }

   onOpenModal = () => {
     this.setState({ openModal: true });
   };

   onCloseModal = () => {
     this.setState({ openModal: false });
   };

   componentDidUpdate(prevProps) {
     // Handle Expired Modal on route change
     if (this.props.location !== prevProps.location) {
       const today = new Date();

       if(this.props.loggedIn && Date.parse(ls.get('accountExpiryDate')) < today && this.props.location.pathname !== '/profile' && this.props.history.location.pathname !== '/pricing' && this.props.history.location.pathname !== '/logout') {
        this.setState({
            openModal: true,
            modalTitle: 'Qasja juaj ka skaduar!',
            modalDescription: '<a href="/profile?confirm_subscription=true">Klikoni këtu për të vazhduar</a> me abonim të rregullt në platformën tonë.',
            modalCloseButton: false})
       }
     }
     // Handle Expired Modal on route change END

   }

   componentDidMount() {

      // Handle Conversion Modal
      if(this.props.loggedIn && !this.props.location.pathname.includes('/profile')) {
         axios.get('modal_check')
           .then(res => {
             const modalStatus = res.data;
               if(modalStatus.action === 'change') {
                 this.setState({
                   openModal: true,
                   modalTitle: modalStatus.title,
                   modalDescription: modalStatus.description,
                   modalCloseButton: true})
               }
            })
      }
      // Handle Conversion Modal END
   }

  render() {
    const { openModal } = this.state;

    return (

      <div className="main_content">

          {this.props.routes.map((route) => (
                    <Route
                      key={route.path}
                      exact path={route.path}
                      render= { () => {
                        return <route.component
                            logInUser={this.logInUser}
                            logOutUser={this.logOutUser}
                            searchString={this.props.searchString}
                            locationData={this.props.location}
                            loggedIn={this.props.loggedIn}
                              /> }}
                    />
           ))}

            <div className="modal_content">
              <Modal
                open={openModal}
                onClose={this.onCloseModal}
                center
                closeOnOverlayClick={false}
                showCloseIcon={this.state.modalCloseButton}
                styles={{ overlay: { background: "#000" } }}>

                <h2 style={{borderBottom:'3px solid yellow'}}>{this.state.modalTitle}</h2>
                <p dangerouslySetInnerHTML={{__html: this.state.modalDescription}} />

              </Modal>
            </div>

      </div>

    );
  }

}

export default withRouter(Body);
