import React, { Component } from "react";
import axios from 'axios';
import ga from 'utils/ga';

class Faq extends Component {

  componentDidMount = () => {
    ga.set({ page: "FAQ" }); // Update the user's current page
    ga.pageview(window.location.pathname); // Record a pageview for the given page
    axios.get('faq') // call endpoint for analytics purposes only
  }

  render() {


    return (
  <div className="row" style={{margin:'0px',padding:'0px'}}>

<div className='col-12 col-md-8'>
<span><b>Sa është pagesa për shfrytezimin e shërbimeve të BIC Tenders?</b></span><br /><br />
<span>Për të përdorur shërbimet tona, keni mundësinë e zgjedhjes prej 3 pakove.&nbsp;
<a href="/pricing" target="_blank">Klikoni këtu</a> për përshkrimin e tyre.</span><br /><br />

<span><b>&Ccedil;ka nënkuptohet si shfrytëzues/kompjuter?</b></span><br /><br />
<span>Me shfrytëzues/kompjuter nënkuptohet njësia prej ku ju qaseni në BIC Tenders. Për shembull,
nëse jeni të abonuar në pakon për Kompani të Vogla, atëhere mund të qaseni nga zyra apo shtëpia, pasi me këtë pako juve ju mundësohet
qasja për 2 shfrytëzues/kompjutera. Por nëse dëshironi që edhe dikush tjetër në zyrën tuaj të ketë qasje në sistemin e BIC Tenders,
atëhere keni mundësi që sistemin ta perdorni edhe tek një kompjuter në zyre, por pastaj nuk do të keni qasje nga shtëpia. Nëse dëshironi
të keni qasje nga më shumë se 2 kompjutera, atëhere mund të abonoheni në pakon për Kompani të Mesme/Mëdha. Qasja nga iPhone, Android dhe
paisjet tjera smartphone është falas për secilën llogari. </span><br /><br /><br /><br />



<p>Për &ccedil;farëdo pyetje apo informacione shtesë, mos hezitoni të na kontaktoni në: ndihma@bic-ks.com.
<br/><br/>
<b>Adresa:</b><br/>
Aktash III V Gervalla<br/>
Bll. VIII HY 5<br/>
10000 Prishtinë, Kosovë</p>


        </div>
  </div>
    );

  }

}



export default Faq;
