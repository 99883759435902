import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Header from './Header.js'
import Footer from './Footer.js'
import Body from './Body.js'
import { privateRoutes, publicRoutes } from '../routes/Routes.js'
import axios from 'axios';
import ga from 'utils/ga';
//import ls from 'local-storage'

/**
 * - Handle login with JWT Authentication
 * - Axios configuration
 * - Initiate Router and logic components
 */


class App extends Component {
  constructor(props){
    super(props);

    this.state = {
       loggedIn: false,
       loggedInButExpired: false,
       accessToken: localStorage.getItem('jwtToken'),
       searchString:''
    }
    
    var today = new Date();
    // JWT authentication
    // Verify locally that there is a JWT token and it's not expired
    if(localStorage.getItem('jwtToken') && Date.parse(localStorage.getItem('expiryDate')) > today ){
      this.state.loggedIn = true;
    } else {
      // No jwt in local-storage, check jwt in GET parameters with vanilla JS
      // ?jwt has to always be the first GET parameter
      if(window.location.href.includes('?jwt=')){
        // Jwt found, verify on server-side that it's valid and not expired. Initially we will assume it's valid, until promise is returned
        const jwtToken = window.location.href.split('?jwt=')[1].split('&')[0]
        this.state.accessToken = jwtToken;
        this.state.loggedIn = true;

        axios.get('https://api.bic-ks.com/token_decode/'+jwtToken)
          .then(res => {
            localStorage.setItem('jwtToken', jwtToken);
            localStorage.setItem('expiryDate', res.data.expiry_date);
            localStorage.setItem('accountExpiryDate', res.data.account_expiry_date.split(' ')[0]);
            this.state.loggedIn = true;
            this.logInUser()
          }).catch( error => {
            alert(JSON.stringify(error))
            this.state.loggedIn = false;
            window.location.href = "/home";
            }
          )
      } else {
        // jwt NOT present
        this.state.loggedIn = false;
      }
    }
    // JWT authentication END

    // axios configuration
    axios.defaults.headers.common['x-header-jwt'] = this.state.accessToken;
    axios.defaults.baseURL = 'https://api.bic-ks.com/'; // http://206.189.2.137:5555/, https://api.bic-ks.com/
    // axios configuration END


    this.logInUser = this.logInUser.bind(this);
    this.logOutUser = this.logOutUser.bind(this);
   }
  componentDidMount = () => {
    ga.set({ page: "App" }); // Update the user's current page
    ga.pageview(window.location.pathname); // Record a pageview for the given page
  }


  logInUser() {
    axios.defaults.headers.common['x-header-jwt'] = localStorage.getItem('jwtToken');
    this.setState({loggedIn: true})
  }

   logOutUser() {
     localStorage.removeItem('jwtToken');
     localStorage.removeItem('expiryDate');
     this.setState({loggedIn: false})
   }

  render() {

  var activeRoutes;
  if (this.state.loggedIn) {
    activeRoutes = privateRoutes;
  } else {
    activeRoutes = publicRoutes;
  }


    return (
      <Router>
        <div>

          <Header
            routes={activeRoutes}
            loggedIn={this.state.loggedIn}
            />

          <Body
            routes={activeRoutes}
            logInUser={this.logInUser}
            logOutUser={this.logOutUser}
            searchString={this.state.searchString}
            loggedIn={this.state.loggedIn}
            />

          <Footer/>

          <Route exact path="/" component={() => (
            <Redirect to="/home" />
          )} />
        </div>
    </Router>
    );
  }
}

export default App;
