import React, { Component } from "react";
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';


class categoryStatsAndTenders extends Component {
  constructor(props) {
		super(props);
    this.categoryRef0 = React.createRef()
    this.categoryRef1 = React.createRef()
    this.categoryRef2 = React.createRef()

		this.state = {
      tenders: this.props.stats.tenders_list,
      sendToTopOfCategory: false,
      topCategoryIndex: 0
    }

    this.sortTenders = this.sortTenders.bind(this);
  }

  sortTenders(sortType) {

    let sortedTenders = []

    if(sortType === 'eurValue') {
      sortedTenders = this.state.tenders.sort((a, b) => (a.integer_values > b.integer_values) ? -1 : 1)
    }else if(sortType === 'publishedDate') {
      sortedTenders = this.state.tenders.sort((a, b) => (a.published_date > b.published_date) ? -1 : 1)
    }
    this.setState({tenders:sortedTenders})

  }

   getTenderData(md5) {
     this.props.getTenderData(md5)
   }

  setCategoryAndNrTendersChild(categoryIndex,nrTenders,buttonState){
    // send to top of category view, if less
    if(buttonState === 'more'){
      this.setState({topCategoryIndex:categoryIndex, sendToTopOfCategory: true})
    }
    // update category component
    this.props.setCategoryAndNrTenders(categoryIndex,nrTenders,buttonState)
  }

  componentDidUpdate(){
    if(this.state.sendToTopOfCategory) {
       this[`categoryRef${this.props.index}`].current.scrollIntoView({ behavior: 'smooth' })	// go to beginning of category
       this.setState({ sendToTopOfCategory: false });
     }
  }

  render() {


    return (
      <div className='category_div'>

      <div ref={this[`categoryRef${this.props.index}`]}></div>
                  <div className='category_info'>

                    <h5>{this.props.stats.name}</h5>

                    <div className='category_short_decorator'>&nbsp;</div>

                    <button  onClick={() => this.sortTenders('publishedDate')}>
                      <span className='category_number'>{this.props.stats.active_tenders_nr}</span>
                    </button>
                    <span className='category_description' style={{marginRight:'5px'}}>tendera aktiv</span>


                    <button  onClick={() => this.sortTenders('eurValue')}>
                        <span className='category_number'>
                          <CountUp
                            end={this.props.stats.active_tenders_eur}
                            duration={1.5}
                            separator=","
                          />
                        </span>
                    </button>

                    <span className='category_description'>EUR</span>
                    <div className='category_long_decorator'>&nbsp;</div>
                  </div>

                  <div className='small_table_div'>
                    <div className='small_table_decorator'>&nbsp;</div>
                      <table className="table table-hover table-bordered" style={{marginBottom:'0px'}}>
                        <tbody>

                        {this.state.tenders.slice(0, this.props.categoryParams[this.props.index].nrTendersToShow).map((tender, index) => {
                                let selectedStatus = ''
                                if(this.props.currentTenderMD5 === tender.md5shi) {
                                  selectedStatus = 'selected_tender'
                                }

                                if(tender.integer_values > 0) {
                                    return <tr key={tender.md5shi} className={selectedStatus}><td>
                                      <Link to={ '/tenders/'+tender.md5shi } onClick={() => this.getTenderData(tender.md5shi)}>{index+1}. {tender.Title} <br/>
                                      <span style={{marginLeft:'0px'}}><b>€{tender.integer_value_pretty}</b></span></Link>
                                    </td></tr>
                                  }
                                else {
                                  return <tr key={tender.md5shi} className={selectedStatus}><td>
                                      <Link to={ '/tenders/'+tender.md5shi } onClick={() => this.getTenderData(tender.md5shi)}>{index+1}. {tender.Title}</Link>
                                    </td></tr>
                                  }
                            })
                          }

                        </tbody>
                      </table>
                    <div className='small_table_decorator'>&nbsp;</div>
                  </div>

                <div className='more_less_container'>
                  <div className='more_less'>


                    { this.props.categoryParams[this.props.index].buttonState === 'more' ?
                      <button  onClick={() => this.setCategoryAndNrTendersChild(this.props.index, this.props.stats.active_tenders_nr, 'less')}>
                        më shumë
                        <img src='/more_arrow.png' alt='more' />
                      </button>
                      :
                      <button  onClick={() => this.setCategoryAndNrTendersChild(this.props.index, 5, 'more')}>
                        <img src='/less_arrow.png' alt='less' />
                        zvogëloje
                      </button>

                    }


                  </div>
                  
                </div>

                <h5 style={{marginTop:'10px'}}>
                  <Link style={{color:'#000',fontSize:'15px', marginBottom:'20px', borderBottom:'0px' }} rel='noopener noreferrer' if you use target="_blank" to={ '/historiku?kategoria='+this.props.stats.category_id } >
                  Klikoni këtu për ti shikuar tenderat paraprak të vitit 2020 dhe 2019
                    </Link></h5><br/><br/>
      </div>
    );
  }

}



export default categoryStatsAndTenders;
