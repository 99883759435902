import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import CountUp from 'react-countup';
import ls from 'local-storage'
import Loading from '../components/Loading.js'
import TableLanding from '../components/TableLanding.js'
import Modal from 'react-responsive-modal';
import { Button } from 'react-bootstrap';
import ga from 'utils/ga';

class HistoryPage extends Component {
  constructor(props) {
    super(props);
    //let selectedPackage = 2

    this.state = {
        params: false,
        allCategoriesTotal: 0,
        category:'',
        categoryText:'',
        activeData: { tenders: [] },
        isLoading: false,
        showModal: false,
        categories: [],
        subscriptionStatus:'',
        totalTenders: 50,
        allTenders:{}
    };


    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);

  }

  handleClose(){
    this.setState({showModal:false})
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ isLoading: true });

    axios.get('https://api.bic-ks.com/historiku/'+value)
      .then(res => {
        const resp = res.data;
        this.setState({ allTenders: resp.tenders_in_months, categoryName: resp.category_name, isLoading: false });
      }).catch(error => {
        alert(error)
        this.setState({ isLoading: false });
      })

    this.setState({
      [name]: value, categoryText: value
    });
  }

  componentDidMount() {

    if(this.props.locationData.search){
        this.setState({ isLoading: true })
        const queryString = require('query-string');
        const parsedParams = queryString.parse(this.props.locationData.search);
        if(parsedParams.kategoria) {
          axios.get('https://api.bic-ks.com/historiku/'+parsedParams.kategoria)
          .then(res => {
            const resp = res.data;
            //alert(JSON.stringify(resp.tenders_in_months))    
            this.setState({ category:parsedParams.kategoria, categoryName: resp.category_name, allTenders: resp.tenders_in_months, totalTenders: resp.total_tenders, isLoading: false });
            //this.setState({ category:parsedParams.kategoria, activeData: resp.tenders_in_months['201911'], totalTenders: resp.total_tenders, params: parsedParams, isLoading: false });
          }).catch(error => {
            alert(error)
            this.setState({ isLoading: false });
          })
        } else {
          this.setState({params: parsedParams}) 
        }
        
    }

    axios.get('last_30_days_total_value')
      .then(res => {
        const resp = res.data;
        this.setState({ allCategoriesTotal: resp});
      })

    axios.get('categories_list')
      .then(res => {
        const categories = res.data;
        this.setState({ categories: categories });
      })

      ga.set({ page: "Landing Page" }); // Update the user's current page
      ga.pageview(window.location.pathname); // Record a pageview for the given page
    
      //setTimeout(() => this.setState({ showModal: true }), 180000)
  }


  render() {
    //const {errors} = this.state;
    //axios.get('subscribe') // call endpoint for analytics purposes only
    ls.set('lastCategory', this.state.category)
    const { showModal } = this.state;
    const tenderMonths = this.state.allTenders;
    const categoryName = this.state.categoryName;

    return (

      <div className="row" style={{marginLeft:'25px'}}>

{this.state.isLoading &&
                          <Loading />
                        }

            <div className="col-md-8">

  
              <div className="form-group">
              <span className='category_number' style={{borderBottom:'3px solid yellow'}}>
              Ndërroje industrinë:
                        </span>

                        <select style={{marginBottom:'10px',marginTop:'10px'}} className="form-control" name="category" value={this.state.category} onChange={this.handleChange}>
                            <option key='random0' value=''>---></option>
                            {this.state.categories.map(category => (
                                <option key={category.cat_id} value={category.cat_id}>{category.cat_name}</option>
                            ))}
                        </select>

                        <div>
                                <span className='category_number' style={{borderBottom:'3px solid yellow'}}>
                                {this.state.totalTenders} për {categoryName}
                                  </span>                        
                                  <span>
                                   <br/> të publikuar dhe të skaduar në vitin 2020 dhe 2019
                                  </span>                                  
                               </div>  

                        {Object.keys(tenderMonths).sort().reverse().map((keyName, i) => (
                                                <>
                        <h5 style={{fontWeight:'bold',marginTop:'15px'}}>{tenderMonths[keyName].Description}</h5>
                        <div className='home_table_div'>
                            <table className="table table-hover table-bordered home_table">
                                  <tbody>
                                                
                                            {tenderMonths[keyName].tenders.map((tender, index) => (
                                              <tr key={index}>
                                                  <td style={{width:'70%'}}>
                                                  <span> <b><Link to={ this.props.loggedIn ? tender.privateURL : tender.publicURL } className='black_link'>{index+1}. {tender.Title}
                                                                          &nbsp;--></Link></b></span><br />
                                                                          {tender.Solicitor}<br/>{tender.PD}
                                                  </td>
                                                  <td style={{width:'20%'}}>
                                                      {tender.integer_values > 0 &&
                                                      <span>
                                                        <CountUp
                                                        end={tender.integer_values}
                                                        duration={2}
                                                        separator=","
                                                        />€
                                                      <br/>
                                                      <span style={{fontSize:'10px'}}>{tender.winner_name}</span>
                                                      </span>
                                                      }  
                                                  </td>
                                              </tr>
                        ))}
                               
                                </tbody>
                          </table>

                      </div>
                           </>     
                        ))}


                        {/* 

 <div className='home_table_div'>
                                <table className="table table-hover table-bordered home_table">
                                <tbody>
                                tenderMonths[keyName].tenders.map((tender, index) => (
                                            <tr key={index}>
                                                <td style={{width:'70%'}}>
                                                <Link to={ this.props.loggedIn ? tender.URL : tender.subscribeURL } className='black_link'> {tender.Title}
                                                &nbsp;--></Link><br />

                                                </td>
                                                <td>
                                                
                                                {tender.integer_values > 0 &&
                                                <span>
                                                    <CountUp
                                                    end={tender.integer_values}
                                                    duration={2}
                                                    separator=","
                                                    />€
                                                    </span>
                                                }  
                                                    
                                                </td>
                                            </tr>
                                )           

                                </tbody>
                                </table>

                                </div>


                        {this.state.activeData.tenders.length > 0 ?
                             <> 



                               <TableLanding tableTitle='' tenders={this.state.activeData.tenders} tableType='small_table_div' loggedIn={this.props.loggedIn} />
                             </>  
                              :
                                 <span style={{marginLeft:'15px'}}>...</span>
                              
                        }    
                    */}


            </div>
            </div>


            <div className='col-md-4'>             
                  
            <div className="modal_content">
              <Modal
                open={showModal}
                showCloseIcon={false}
                focusTrapped={false}
                onClose={this.handleClose}
                center
                closeOnOverlayClick={false}
                closeOnEsc={false}               
                styles={{ overlay: { background: "#000", padding:'40px' } }}>

                <h4 style={{borderBottom:'3px solid yellow'}}>A doni me e rritë fitimin tuaj në vitin 2020?</h4>
                <div style={{textAlign:'center'}}>

                <Button variant="warning" onClick={this.handleClose} href={'regjistrimi?kategoria='+this.state.category}>
                  Po
                </Button>&nbsp;
                <Button variant="warning" onClick={this.handleClose}>
                  Jo
                </Button>
                </div>
              </Modal>
            </div>


            </div>


    </div>




    );
  }
}

export default withRouter(HistoryPage);
