import React, { Component } from "react";
import axios from 'axios';
import ga from 'utils/ga';


class Pricing extends Component {

  componentDidMount = () => {
    ga.set({ page: "App" }); // Update the user's current page
    ga.pageview(window.location.pathname); // Record a pageview for the given page
    axios.get('pricing') // call endpoint for analytics purposes only
  }



  render() {
    const baseUrlForRegistration = this.props.loggedIn ? '/profile?confirm_subscription=true&pako':'/subscribe?pako'
    const buttonActionText = this.props.loggedIn ? 'Abonohu':'Regjistrohu'

    return (
      <section class="pricing py-5">
          <div class="row">

          {/* <!-- Pro Tier --> */}
          <div class="col-lg-4">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title text-muted text-uppercase text-center">Pako Kompani të Mesme/Mëdha</h5>
                <h6 class="card-price text-center">424.8&euro; në vit<br /><small>(apo 30&euro; në muaj pa TVSH)</small></h6>
                <hr />
                <ul class="fa-ul">
                  <li><span class="fa-li"><i class="fas fa-check"></i></span>Notifikimi ditor me e-mail me tendera të publikuar në Kosovë</li>
                  <li><span class="fa-li"><i class="fas fa-check"></i></span>Qasja në BIC Tenders për 4 shfrytëzues/kompjutera</li>


                </ul>
                <a href={baseUrlForRegistration+"=3"} class="btn btn-block btn-primary text-uppercase">{buttonActionText}</a>
              </div>
            </div>
          </div>

            {/* <!-- Plus Tier --> */}
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Pako Për Kompani të Vogla</h5>
                  <h6 class="card-price text-center">325.68&euro; në vit<br /><small>(apo 23&euro; në muaj pa TVSH)</small></h6>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Notifikimi ditor me e-mail me tendera të publikuar në Kosovë</li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Qasje në BIC Tenders për 2 shfrytëzues/kompjutera</li>


                  </ul>
                  <a href={baseUrlForRegistration+"=2"} class="btn btn-block btn-primary text-uppercase">{buttonActionText}</a>
                </div>
              </div>
            </div>

            {/* <!-- Free Tier --> */}
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Pako Për Individ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h5>
                  <h6 class="card-price text-center">212.4&euro; në vit<br /><small>(apo 15&euro; në muaj pa TVSH)</small></h6>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Notifikimi ditor me e-mail me tendera të publikuar në Kosovë</li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Qasje në BIC Tenders për një shfrytëzues/lokacion</li>
              

                  </ul>
                  <a href={baseUrlForRegistration+"=1"} class="btn btn-block btn-primary text-uppercase">{buttonActionText}</a>
                </div>
              </div>
            </div>

          </div>
      </section>

    );

  }

}



export default Pricing;
