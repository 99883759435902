import React, { Component } from "react";
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';

class TableLarge extends Component {

  render() {
    return (
      <div className='col-12'>

         {this.props.tableTopText.length > 0 &&
            <div className='home_table_decorator'>
              <span style={{paddingLeft:'12px',fontSize:'12px'}}>{this.props.tableTopText}</span>
            </div>
         }

          <div className='home_table_div'>
                <table className="table table-hover table-bordered home_table">
                  <tbody>
                      {this.props.tableItems.length ?
                        this.props.tableItems.map((stats, index) => (
                              <tr key={index}>
                                 <td>
                                   <Link to={ '/landing?kategoria='+stats.category_id } className='black_link'>
                                     {stats.category_name}&nbsp;--></Link>
                                   </td>
                                 <td>
                                 <Link to={ '/landing?kategoria='+stats.category_id } className='black_link'> 
                                     <CountUp
                                       end={stats.category_value}
                                       duration={2}
                                       separator=","
                                     /> €
                                  </Link>   
                                  </td>
                               </tr>

                         ))
                         :
                         ('Duke u ngarkuar...')
                      }

                  </tbody>
                </table>
                {this.props.tableBottomText.length > 0 &&
                  <p style={{fontSize:'10px'}}>{this.props.tableBottomText}</p>
                }
          </div>

      </div>
    )
  }

}

export default TableLarge;
