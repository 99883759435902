import React, { Component } from "react";

class Footer extends Component {
  render() {
      const currentYear = new Date().getFullYear();
      return (

        <div className='col-12 footer' style={{textAlign:'center'}} >

          <div className='index-z-footer'>
              <p>BIC Tenders | ndihma@bic-ks.com
              <br/>Aktash III, Bll8 H5, 10000 Prishtinë, Kosovë</p>
          </div>

          <p className='bottom_text'>©  BIC Tenders 2011-{currentYear}  - Te gjitha te drejtat e rezervuara.<br/>
            <a href="/terms">Privacy and Refund Policy</a>
          </p>

        </div>

      );
  }
}

export default Footer;
