import React, { Component } from "react";
import axios from 'axios';
import TableLarge from '../components/TableLarge.js'
import ga from 'utils/ga';

class Home extends Component {
  constructor(props) {
    super(props)
      this.state = {
         monthlyStats: [],
         isLoading: true,
         open: false
      }
  }


  componentDidMount = () => {
    axios.get('last_30_days_publications')
    .then(res => {
      const monthlyStats = res.data;
      this.setState({ monthlyStats: monthlyStats });
    })
    ga.set({ page: "Home" }); // Update the user's current page
    ga.pageview(window.location.pathname); // Record a pageview for the given page
  }

  render() {
    const sortedArray = this.state.monthlyStats.sort((a, b) => (a.category_value > b.category_value) ? -1 : 1)
    const tableTopText = 'Vlerat e tenderëve në 30 ditët e fundit*'
    const tableBottomText = '*Nëse një tender i përket disa kategorive, atëhere ai llogaritet për secilën kategori. Publikimet përfshijnë tenderët e ri si dhe dhëniet e kontratës.'


    return (

  <div className="row" style={{margin:'0px',padding:'0px',marginLeft:'20px'}}>

        <div className='col-12 col-lg-5' style={{borderRight:'1px solid #e5e5e5'}}>
          <h4 style={{paddingBottom:'4px'}}>Informacion rreth<br/> <b>BIC Tenders</b></h4>
          <div className='category_long_decorator' style={{marginBottom:'10px'}}>&nbsp;</div>
          <p style={{margin:0, marginBottom:'8px'}}>BIC Tenders bën monitorimin dhe shpërndarjen e njoftimeve për tendera të publikuar nga institucionet vendore, 
            ndërkombëtare dhe private. </p>
            <p style={{margin:0, marginBottom:'8px'}}>
            Me mesatarisht mbi 7,559,055 EUR tendera në ditë, ku mbi 50% të tenderave janë nën vlerën 30,000 EUR, abonimi në BIC Tenders ofron mundësi për të gjithë.
            </p>
          <p>
            Ju ftojmë t'i eksploroni tenderat sipas industrisë, në tabelën në vijim:
          </p>
          
        </div>

        <div className='col-12 col-lg-7'>
            <h4 style={{paddingBottom:'4px'}}><b>Statistikat</b> e publikimeve</h4>
            <p style={{fontSize:'13px',marginBottom:'5px'}}>Në tabelën e mëposhtme mund të gjeni disa statistika rreth tenderëve të publikuar:</p>

            <div className='category_long_decorator' style={{marginBottom:'10px',marginTop:'10px'}}>
            &nbsp;
            </div>

            <TableLarge tableItems={sortedArray} tableTopText={tableTopText} tableBottomText={tableBottomText} />

        </div>




  </div>
    );

  }

}



export default Home;
