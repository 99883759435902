import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ls from 'local-storage';
import Modal from 'react-responsive-modal';
import Countdown from 'react-countdown';
import ga from 'utils/ga';

class Regjistrimi extends Component {
	constructor(props) {
		super(props);		
		this.state = {
			contactField: '',
			minuteRange: 0, 		  
			priceRange: 0,
			showModal: false
		}
		//ls.clear()
		const previousVisit = ls.get('ofertaFundit')// Date.parse(ls.get('accountExpiryDate'));
		if(!previousVisit) {
			// no previous visit, setting cookie
			// eslint-disable-next-line
			var date = Date()
			ls.set('ofertaFundit', date)
		} else {
			// user already visited today, offer is expired, therefore modal is shown
			var today = new Date();
			var previousDate = new Date(ls.get('ofertaFundit'));
			const daysDifference = today.getDate() - previousDate.getDate()
			if(daysDifference === 0){
				this.state.showModal = true;
			} else {
				// eslint-disable-next-line
				var date = Date()
				ls.set('ofertaFundit', date)				
			}
			
		}

	this.handleChange = this.handleChange.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);	
	this.handleCountdownComplete = this.handleCountdownComplete.bind(this); 
	}	


	componentDidMount() {
		const minuteRange = Math.floor(Math.random() * (9 - 3)) + 3
		const priceRange = Math.floor(Math.random() * (131 - 90)) + 90
		this.setState({minuteRange, priceRange})
	}	

	handleSubmit(e) {
		e.preventDefault();
		if(this.state.contactField.length < 6 ) {
			alert('Ju lutemi shenoni emailin ose numrin tuaj')
		} else {
			alert('Kontakti: ' + this.state.contactField + ', cmimi i ofertes: '+this.state.priceRange)
		}
		
	}

	handleChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
	
		this.setState({
		  [name]: value
		});
	  }

	  onKeyPress = (e) => {
		if(e.which === 13) {
		  this.handleSubmit(e);
		}
	  }


	  handleCountdownComplete() {
		this.setState({showModal:true})		
	}

	  renderer = ({ minutes, seconds, completed }) => {
		  // Render a countdown
		  return <span> Nxitoni, zbritja skadon edhe  
					  <span style={{color:'red', fontWeight:'bold'}}> {minutes} min e {seconds} sekonda</span>.
				  </span>;
	  };

	  componentDidMount = () => {
		ga.set({ page: "Regjistrimi" }); // Update the user's current page
		ga.pageview(window.location.pathname); // Record a pageview for the given page
	  }

	render() {
		

		  const millisecondsRange = this.state.minuteRange * 60000 - 1000
		  const disabled = this.state.contactField.length < 6 ? true : false;

		 
		return (

			<div className='col-md-8' style={{marginTop:'0px'}}>			
		 	 
			  <div style={{marginLeft:'25px'}}>

				  <p style={{fontSize:'18px'}}>Me mesatarisht mbi <b>7,559,055 EUR</b> tendera në ditë, ku mbi 50% të tenderave janë nën vlerën <b>30,000 EUR</b>, abonimi në BIC Tenders ofron mundësi për të gjithë.
				  <br /><br />
				  Abonohuni sot, dhe përfitoni zbritjen nga <b><strike>212.4 EUR</strike> në {this.state.priceRange} EUR</b> për abonim 1 vjeçar.
				   <br /><br /><Countdown date={Date.now() + millisecondsRange} renderer={this.renderer} onComplete={this.handleCountdownComplete} />
				  </p> 


					<div className="form-group">
						<input type="text"  onKeyPress={this.onKeyPress}  onChange={this.handleChange} name="contactField" className="form-control input-sm" placeholder="Shkruaje Emailin ose Telefonin" />
					</div>
					<Button variant="warning" onClick={this.handleSubmit} href={'regjistrimi?kategoria='+this.state.category} disabled={disabled}>
						Abonohu tani!
					</Button>

			 </div>	
				
						<div className="modal_content">
							<Modal
								open={this.state.showModal}
								showCloseIcon={false}
								focusTrapped={false}
								onClose={this.handleChange}
								center
								closeOnOverlayClick={false}
								closeOnEsc={false}               
								styles={{ overlay: { background: "#000", padding:'40px' } }}>

								<h4 style={{borderBottom:'3px solid yellow'}}>Zbritja ka skaduar.</h4>
								
								<div style={{textAlign:'center'}}>
									<Button variant="warning" onClick={this.handleClose} href={'/home'}>
										Kaloni tek faqja kryesore
									</Button>
								</div>
							</Modal>
						</div>

			</div>

		);
	}

}



export default withRouter(Regjistrimi);
