import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';

/**
 * - Generate Top Navigation
 */


class Header extends Component {

render() {
  return     (

    <div className="row">
      <div className="col-12 header">
            <div className='col-12'>
                  <a href='/home' className="navbar-brand"><img alt='BIC logo' src='/logo.png' className='logo' /></a>
                  <button  style={{float:'right',marginTop:'25px'}} className="d-block d-sm-none navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon">
                          <i className="fas fa-bars" style={{color:'#fff',fontSize:'28px'}}></i>
                        </span>
                  </button>
            </div>

            {/*<!-- menu -->*/}
              <div className='col-12' >
                <nav className="navbar navbar-expand-md" style={{marginBottom:'3px',padding:'0'}}>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                      <ul className="navbar-nav">
                          {this.props.routes.map((route) => {
                                if(route.showInMenu){
                                  return  <li className="nav-item" key={route.path}>
                                            <NavLink
                                              key={route.path}
                                              to={route.path}
                                              exact path={route.path}
                                              className={this.props.location.pathname.includes(route.path.split('/')[1])?'active':'regular'}>
                                            {route.name}
                                            </NavLink>
                                          </li>
                                    } else {
                                      return false
                                    }
                             })}
                      </ul>
                    </div>
                </nav>
              </div>
            {/*<!-- menu END -->*/}


      </div>
    </div>

        );
 }
}

export default withRouter(Header);
