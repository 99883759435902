import React, { Component } from "react";

class Loading extends Component {

  render() {
    return (
      <div className="loading_div"><img src='/loading.gif' alt='loading...' /></div>
    )
  }

}

export default Loading;
